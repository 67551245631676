// import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";

export function getList(data) {
    return dmshttp({
        url: 'agentReturnOrderList',
        data
    })
}

export function batch(data) {
    return dmshttp({
        url: 'batchInvalidSalesOrder',
        data
    })
}


// 驳回
export function orderReject(data) {
    return dmshttp({
        url: 'rejectPurchaseOrder',
        data
    })
}



//取消审核(
export function cancleReject(data) {
    return dmshttp({
        url: 'deleteReserverBySoId',
        data
    })
}

// 导出

export function exportOrder(data) {
    return dmshttp({
        url: 'exportAgentReturnOrderList' + '&type=1',
        data,
        responseType: 'blob'
    })
}